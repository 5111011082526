import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="final-comment">
        <h3> 방문해주셔서 감사합니다.</h3>
      </div>
      <div className="develop-date">
        <h5>@2024 </h5> <h5>|</h5> <h5>v.1.0.0 </h5>
      </div>
    </div>
  );
};

export default Footer;
