import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TypeIt from "typeit-react";
import "./Main.scss";
import TerminalBar from "../../components/TerminalBar/TerminalBar";
import Skills from "../../components/Skills/Skills";
import Projects from "../../components/Projects/Projects";

const Main = () => {
  const [isNextStart, setIsNextStart] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="main-content">
      <section id="introduce">
        <TerminalBar title="Introduce Me" />
        <div className="typeit-wrapper">
          <TypeIt
            options={{
              speed: 100,
              loop: false,
              afterComplete: () => {
                setIsNextStart(true);
              },
            }}
          >
            선풍기처럼 동작하는 개발자
          </TypeIt>
        </div>
        {isNextStart && (
          <div className="about-wrapper">
            <TypeIt options={{ speed: 50, loop: false }}>
              선풍기는 첨단 기술의 산물은 아니지만, 꾸준히 개선되어왔고 누구나
              쉽게 사용할 수 있습니다.
              <br />
              또한, 다양한 각도와 회전으로 많은 사람들에게 시원한 바람을
              제공하며
              <br />
              오랫동안 사용 가능하고 에어컨에 비해 고치기도 쉽습니다.
              <br />
              <br /> 저 역시 선풍기처럼 다양한 사용자의 요구를 고려하여 서비스를
              설계하고 재사용 가능한 코드를 작성하려 노력하였으며,
              <br /> 기존의 서비스를 먼저 학습하고 지속해서 개선하여 다양한
              고객층이 쉽게 사용할 수 있는
              <br /> 오랫동안 사랑받는 서비스를 만들고자 합니다.
            </TypeIt>
          </div>
        )}
      </section>
      <hr className="port-line" />
      <Skills />
      <hr className="port-line" />
      <Projects />
    </div>
  );
};

export default Main;
