import React, { useState } from "react";
import "./WhoDucKing.scss";

const WhoDucKing = () => {
  const [showErd, setShowErd] = useState(false);
  const [showResponsiveMain, setShowResponsiveMain] = useState(false);

  const toggleErd = () => {
    setShowErd(!showErd);
  };

  const handleClick = () => {
    setShowResponsiveMain(!showResponsiveMain);
  };

  const handleClose = () => {
    setShowResponsiveMain(false);
  };

  return (
    <div className="project-main-container">
      <section id="project-header">
        <img src="/images/projects/whoducking/logo.png" className="logo" />
        <h1 className="project-title">WhoDucKing </h1>
        <span className="gray-text">누가 덕후의 왕인가! </span>
        <p className="project-info-box">
          <span className="project-date">
            개발 기간 : 2024.06.03 ~ 2024.07.05 &nbsp;
          </span>
          |<span className="project-team"> 팀원 : 6인</span>
          <br />
        </p>
        <span className="roll">담당역할 : BackEnd + FrontEnd</span>
        <div className="description-box">
          <h3>[ 프로젝트 소개 ]</h3>
          <p>
            <b>애니메이션 매니아들을 위한 커뮤니티</b>로, 다양한 애니관련
            컨텐츠를 통해 사용자의 흥미를 유발시키는 사이트입니다.
          </p>
          <h3>[ 프로젝트 선정 이유 ]</h3>
          <p>
            애니메이션을 좋아하는 사람들이 늘어남에따라 관심가는
            애니메이션에대한 토론을 하는 커뮤니티의 부재를 발견하였습니다.
            <br />
            사람들이 애니메이션에대해 자유롭게 토론할 수 있고 최신 애니정보를
            보여줄 수 있는 사이트를 만들어보고자 프로젝트를 진행하였습니다.
          </p>
          <p></p>
          <h3>[ 사용한 기술 ]</h3>
          <img
            src="/images/projects/whoducking/skills.png"
            className="project-image"
          />
          <h3 onClick={toggleErd} className="erd-title">
            [ ERD ]
          </h3>
          {showErd && (
            <img
              src="/images/projects/whoducking/erd.png"
              alt="ERD"
              className="project-image"
            />
          )}
          <a href="https://www.figma.com/design/3hqbQGQjwIbD0V6dmTcwey/Who's-DucKing-(Copy)?node-id=0-1&node-type=canvas&t=DSS3yvYv1aYuNECJ-0">
            <h3>[ FIGMA ]</h3>
          </a>
        </div>
      </section>
      <hr />
      <section id="main-page">
        <h1 className="page-title">메인 페이지 </h1>
        <div className="page-introduce">
          <img
            src="/images/projects/whoducking/main.png"
            alt="메인페이지"
            className="main-image"
          />
          <div className="red-box main-desc-box">
            <img
              src="/images/projects/whoducking/top10.png"
              className="top10"
            />
          </div>
          <div
            className="responsive-main-box hide-box"
            style={{ display: showResponsiveMain ? "block" : "none" }}
          >
            {/* <button className="close-button" onClick={handleClose}>
              X
            </button> */}
            <img
              src="/images/projects/whoducking/whoduck.png"
              alt="반응형 메인"
              className="responsive-main"
            />
          </div>
          <div className="text-description">
            <h3>[ 구현 기능 ]</h3>
            <h4> 1. 메인페이지 반응형 구현</h4>
            <p className="show-button change-color" onClick={handleClick}>
              반응형 페이지 보기
            </p>
            <h4> 2. TOP 10 인기 애니</h4>
            <p></p>
            <h3>[ 트러블 슈팅 ]</h3>
            <h4>Natural Query 적용 안되는 문제</h4>
          </div>
        </div>
      </section>
      <hr />
      <section id="ani-list-page">
        <h1 className="page-title">애니메이션 목록 페이지 </h1>
        <div className="page-introduce">
          <h3>[ 애니메이션 페이지 ]</h3>
          <div className="ani-list-box">
            <img
              src="/images/projects/whoducking/ani_list_page.png"
              alt="aniList"
              className="ani-list-image"
            />
            <div className="text-description">
              <h3>[ 구현 기능 ]</h3>
              <h4> 1. 애니메이션 필터링</h4>
              <p className="show-button" onClick={handleClick}>
                반응형 페이지 보기
              </p>
              <h4> 2. 페이지네이션 </h4>
              <p></p>
              <h3>[ 트러블 슈팅 ]</h3>
            </div>
          </div>
          <h3>[ 애니메이션 페이지 : 반응형]</h3>
          <div className="ani-list-box">
            <img
              src="/images/projects/whoducking/anilist-small.png"
              alt="aniList"
              className="ani-list-image"
            />
            <img
              src="/images/projects/whoducking/anilist-small2.png"
              alt="aniList"
              className="ani-list-image2 "
            />
            <div className="text-description">
              <h3>[ 구현 기능 ]</h3>
              <h4> 1. 애니메이션 필터링</h4>
              <p className="show-button" onClick={handleClick}>
                반응형 페이지 보기
              </p>
              <h4> 2. 페이지네이션 </h4>
              <p></p>
              <h3>[ 트러블 슈팅 ]</h3>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section id="ani-detail-page">
        <h1 className="page-title">애니메이션 상세 페이지 </h1>
        <div className="page-introduce">
          <h3>[ 애니메이션 페이지 ]</h3>
          <div className="ani-list-box">
            <img
              src="/images/projects/whoducking/ani_detail_page.png"
              alt="aniList"
              className="ani-list-image"
            />
            <div className="text-description">
              <h3>[ 구현 기능 ]</h3>
              <h4> 1. 애니메이션 필터링</h4>
              <p className="show-button" onClick={handleClick}>
                반응형 페이지 보기
              </p>
              <h4> 2. 페이지네이션 </h4>
              <p></p>
              <h3>[ 트러블 슈팅 ]</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="retrospect"></section>
    </div>
  );
};

export default WhoDucKing;
