import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useLocation } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  const [showNav, setShowNav] = useState(true);
  const location = useLocation();
  const param = useParams();

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     setShowNav(true);
  //   } else {
  //     setShowNav(false);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname.startsWith("/projects") ||
      location.pathname.startsWith("/aboutMe")
    ) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="category-container">
        <a href="/">
          <span className="category-button" onClick={() => setShowNav(true)}>
            Main
          </span>
        </a>
        <a href="https://github.com/Kimsu10" rel="noopener noreferrer">
          <span className="category-button">GitHub</span>
        </a>
        <a href="https://gerede.tistory.com/" rel="noopener noreferrer">
          <span className="category-button">Blog</span>
        </a>
        <RouterLink to="/aboutMe">
          <span className="category-button">About Me</span>
        </RouterLink>
      </div>
      <div className="nav">
        <h2 className="nickname">Kimsu10</h2>
        {showNav && (
          <ul>
            <li>
              <ScrollLink
                to="introduce"
                smooth={true}
                duration={300}
                offset={-300}
              >
                Intro
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="skills"
                smooth={true}
                duration={300}
                offset={-190}
              >
                Skills
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="projects"
                smooth={true}
                duration={300}
                offset={-180}
              >
                Projects
              </ScrollLink>
            </li>
          </ul>
        )}
        {!showNav && location.pathname.startsWith("/projects") && (
          <div className="project-nav"></div>
        )}
        {!showNav && location.pathname.startsWith("/aboutMe") && (
          <div className="project-nav"></div>
        )}
      </div>
    </header>
  );
};

export default Header;
