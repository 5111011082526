import React from "react";
import "./TerminalBar.scss";

const TerminalBar = ({ title }) => {
  return (
    <div className="terminal-bar">
      <h1>{title}</h1>
      <div className="circle-box">
        <div className="red circle"></div>
        <div className="yellow circle"></div>
        <div className="green circle"></div>
      </div>
    </div>
  );
};

export default TerminalBar;
