import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Main from "./pages/Main/Main";
import AboutMe from "./pages/AboutMe/AboutMe";
import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";
import ProjectDetail from "./pages/ProjectsDetail/ProjectDetail";

const AppRouter = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/aboutMe" element={<AboutMe />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/projects" element={<ProjectDetail />} />
        <Route path="/projects/:projectId" element={<ProjectDetail />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
