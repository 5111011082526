import React, { useState, useEffect } from "react";
import "./Projects.scss";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const [teamProjects, setTeamProjects] = useState(null);
  const [companyProjects, setCompanyProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState("team");

  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      fetch("/data/projects.json").then((res) => res.json()),
      fetch("data/intern.json").then((res) => res.json()),
    ])
      .then(([teamProjects, companyProjects]) => {
        setTeamProjects(teamProjects);
        setCompanyProjects(companyProjects);
        setLoading(false);
      })
      .catch((err) => {
        console.error("fetch error: ", err);
        setLoading(false);
      });
  }, []);

  const handleProjectClick = (cardClassName) => {
    navigate(`/projects/${cardClassName}`);
  };

  return (
    <section id="projects">
      <h1 className="section-title">Projects</h1>
      <div className="project-select-box">
        <span
          className={`team-button ${
            selectedProject === "team" ? "active" : ""
          }`}
          onClick={() => setSelectedProject("team")}
        >
          TEAM
        </span>
        <span
          className={`intern-button ${
            selectedProject === "intern" ? "active" : ""
          }`}
          onClick={() => setSelectedProject("intern")}
        >
          INTERN
        </span>
      </div>

      {selectedProject === "team" &&
        teamProjects?.map((project, idx) => (
          <div
            key={idx}
            className={project.cardClassName}
            data-aos="fade-up"
            // onClick={() => handleProjectClick(project.cardClassName)}
          >
            <div className="project-card-inner">
              <img
                src={project.imgSrc}
                alt={project.title}
                className="project-image"
              />
              <div className="project-content">
                <h2>{project.title}</h2>
                <p>
                  <strong>기간:</strong> {project.period}
                </p>
                <p>{project.description}</p>
                <p>
                  <strong>맡은 역할: </strong> {project.roll}
                  <br />
                  <strong>기술 스택:</strong> {project.techStack.join(", ")}
                </p>
                <ul>
                  {project.contributions.map((contribution, idx) => (
                    <li key={idx}>{contribution}</li>
                  ))}
                </ul>
                <div className="project-links-detail">
                  {project.details.ppt && (
                    <a
                      href={project.details.ppt}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      PPT
                    </a>
                  )}
                  {project.details.youtube && (
                    <a
                      href={project.details.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      YouTube
                    </a>
                  )}
                  {project.details.git && (
                    <a
                      href={project.details.git}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      GitHub
                    </a>
                  )}
                  {project.details.notion && (
                    <a
                      href={project.details.notion}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      Notion
                    </a>
                  )}
                  {project.details.blog && (
                    <a
                      href={project.details.blog}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      Blog
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

      {selectedProject === "intern" &&
        companyProjects?.map((project, idx) => (
          <div
            key={idx}
            className={project.cardClassName}
            data-aos="fade-up"
            // onClick={() => handleProjectClick(project.cardClassName)}
          >
            <div className="project-card-inner">
              <img
                src={project.imgSrc}
                alt={project.title}
                className="project-image"
              />
              <div className="project-content">
                <h2>{project.title}</h2>
                <p>
                  <strong>기간:</strong> {project.period}
                </p>
                <p>{project.description}</p>
                <p>
                  <strong>맡은 역할: </strong> {project.roll}
                  <br />
                  <strong>기술 스택:</strong> {project.techStack.join(", ")}
                </p>
                <ul>
                  {project.contributions.map((contribution, idx) => (
                    <li key={idx}>{contribution}</li>
                  ))}
                </ul>
                <div className="project-links-detail">
                  {project.details.ppt && (
                    <a
                      href={project.details.ppt}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      PPT
                    </a>
                  )}
                  {project.details.youtube && (
                    <a
                      href={project.details.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      YouTube
                    </a>
                  )}
                  {project.details.git && (
                    <a
                      href={project.details.git}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      GitHub
                    </a>
                  )}
                  {project.details.blog && (
                    <a
                      href={project.details.blog}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="project-button"
                    >
                      Blog
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};

export default Projects;
