import React from "react";
import "./Making.scss";

const MakingPage = () => {
  return (
    <div className="making_body">
      <h1>빠른 시일내로 구현하겠습니다.</h1>
      <img
        className="sorry_image"
        alt="공사중 이미지"
        src="/images/sorry.jpg"
      />
      <img
        className="sorry_image2"
        alt="공사중 이미지2"
        src="/images/sorry2.jpg"
      />
    </div>
  );
};

export default MakingPage;
