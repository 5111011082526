import React, { useState, useEffect } from "react";
import "./Skills.scss";

const Skills = () => {
  const [skillsData, setSkillsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/data/skillIcon.json`)
      .then((res) => res.json())
      .then((data) => setSkillsData(data))
      .catch((err) => {
        console.error("fetch error : " + err);
        setLoading(false);
      });
  }, []);

  const renderSkills = (category) => {
    if (!skillsData) return null;

    const foundCategory = skillsData.find(
      (skillCategory) => skillCategory[category.toLowerCase()]
    );

    if (foundCategory) {
      const skills = foundCategory[category.toLowerCase()];

      return skills.map((skill, idx) => {
        const skillName = Object.keys(skill)[0];
        const skillImage = skill[skillName];

        return (
          <li key={idx} className="skill-item">
            <img src={skillImage} alt={skillName} className={skillName} />
          </li>
        );
      });
    }

    return null;
  };

  return (
    <section id="skills">
      <h1 className="section-title">Skills</h1>
      <div className="skills-section">
        <h2 className="skill-list-title">[ Front-End ]</h2>
        <ul className="skills-list">{renderSkills("frontend")}</ul>
      </div>

      <div className="skills-section">
        <h2 className="skill-list-title">[ Back-End ]</h2>
        <ul className="skills-list">{renderSkills("backend")}</ul>
      </div>

      <div className="skills-section">
        <h2 className="skill-list-title">[ DevOps ]</h2>
        <ul className="skills-list">{renderSkills("devops")}</ul>
      </div>

      <div className="skills-section">
        <h2 className="skill-list-title">[ Cooperation ]</h2>
        <ul className="skills-list">{renderSkills("version")}</ul>
      </div>

      <div className="skills-section">
        <h2 className="skill-list-title">[ Tools ]</h2>
        <ul className="skills-list">{renderSkills("tools")}</ul>
      </div>
    </section>
  );
};

export default Skills;
