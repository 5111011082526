import React, { useEffect } from "react";
import "./AboutMe.scss";
import AOS from "aos";

const AboutMe = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="introduce-container">
      <h1 className="page-title">About Me</h1>
      <section
        id="introduce"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-delay="400"
      >
        <div className="introduce-box">
          <h1 className="jua-regular section-title"> 김 수 정</h1>
          <div className="short-intro">
            <b>시작과 끝을 넘어, 과정을 즐기는 개발자</b>
          </div>
          <p className="introduce-text">
            "프로젝트를 설계하고 구현하여 완성하는 뿌듯함에 더불어
            <br />
            프로젝트를 진행하면서 마주치는 다양한 트러블을 해결하는 과정도
            좋아합니다."
            <br /> <br />
            "정해진 답은 없다고 생각하여 다양한 오류를 만들고 해결하는 과정에서
            <br /> 자주 팀원들에게 둘러쌓이고 왜 이문제가 발생했는지 함께
            공부하였습니다."
            <br /> <br />
            <b>문제를 해결했을 때의 성취감</b>과
            <b>팀원의 새로운걸 배웠다는 말을 들을 때의 기쁨</b>은
            <br /> 저의 개발에대한 원동력이며 여전히 개발이 즐거운 이유입니다.
            <br />
            제가 느끼는 이 즐거움을 회사에서 동료들과 함께하고 싶습니다.
          </p>
        </div>
        <div className="image-card">
          <img
            className="profile-image"
            src="/images/introduce/prof-img.jpeg"
          />
        </div>
      </section>
      <section id="my-purpose" data-aos="flip-left" data-aos-delay="400">
        <h1 className="jua-regular section-title">
          부족한 점을 개선하는 행동력
        </h1>
        <p className="detail-text">
          <b>백엔드 개발자</b>로서{" "}
          <u>
            ERD 설계, 인증/인가 구현, 그리고 기본적인 CRUD 기능을 갖춘 RESTful
            서버를 구현
          </u>
          하였습니다.
          <br />
          하지만 백엔드만 이해하는 것으로는 소통의 어려움이있다고 생각하여 업무
          효율성을 높이고자 프론트 개발에 참여하였습니다.
          <br />
          <b>프론트 개발자</b>로 프로젝트를 진행하며
          <u>
            사용자에 대한 이해를 높이고 전체적인 프로젝트를 보는 시야를 확장
          </u>
          할 수 있었습니다.
          <br />
          프론트개발을 하면서
          <b>소통의 문제를 개선하고자 PM과 팀장을 하며 </b>
          팀원들의 다양한 의견을 조율하고 통합하는데 흥미를 느꼈습니다.
          <br /> 이후, DevOps에 관심을 가지고 <b>Naver Cloud DevOps 과정</b>을
          수료하면서 <u>CI/CD와 배포자동화에대한 이해</u>를 쌓았습니다.
          <br />
          <br />
          이제는 회사에 입사하여 전문성을 기르고 실적을 인정받고 싶습니다.
          <br /> 또한 지금까지의 경험과 지식을 공유하여 사내 팀원들과 함께 고
          성장하여 언젠가는 개발팀을 이끄는 역할을 해보고싶습니다.
        </p>
      </section>
      <section id="my-first-thing" data-aos="flip-left" data-aos-delay="400">
        <h1 className="jua-regular section-title">
          개발자로서 가장 중요하게 생각하는 것은
        </h1>
        <p className="detail-text">
          <b>"커뮤니케이션"</b> 과 <b>"협업 능력"</b>이라고 말씀드리고 싶습니다.
          <br /> AI가 등장함에따라 과거에 비해 구현 난이도와 속도가 많이
          개선되었지만, 아직 업무는 AI가 아닌 사람과 함께하기 때문입니다.
          <br /> <br />
          제게 가장 기억에 남는 경험은{" "}
          <b>소통의 부재를 해결하기 위해 PM으로 참여</b>한 첫 프론트엔드
          프로젝트 때의 일입니다.
          <br /> 백엔드와 프론트엔드 간의{" "}
          <u>소통 문제로 인해 작업이 지연되는 상황을 개선하고자</u>{" "}
          <b>팀장을 자원</b>하였고
          <br /> <b>프로덕트 선정</b>부터 <b>기획</b>,{" "}
          <b>ERD 설계 및 API 문서화작업을 주도하고 설명</b> 하여 팀원들과 함께
          하였습니다.
          <br /> 문제가 발생하거나 질문이 있을 때는 즉시 그 개발자와 함께
          논의하여 문제를 해결하려 노력했습니다.
          <br /> 그러나{" "}
          <b>통신 과정에서 네이밍 컨벤션 불일치와 데이터 누락 문제가 발생</b>
          하였습니다.
          <br />
          이는 <u> ChatGPT 사용으로 인한 코드 변화가 원인</u>이였습니다.
          <br /> 그날 저녁 회의를 통해 <b>기존 네이밍 규칙으로 코드를 수정</b>
          하고, 변경할 수 없는 DB 부분은{" "}
          <b>프론트에서 목 데이터를 활용하여 해결</b>했습니다.
          <br /> 이후 데일리 미팅을 통해 지속적인 소통을 유지하면 서 프로젝트를
          성공적으로 마무리할 수 있었습니다. <br /> 이 경험을 통해{" "}
          <b>소통의 중요성</b>과 <b>체계적인 협업</b>이 프로젝트의 성공에 얼마나
          중요한지 다시 한 번 깨닫게 되었습니다.
        </p>
      </section>
      <section id="my-solution" data-aos="flip-left" data-aos-delay="400">
        <h1 className="jua-regular section-title">
          문제가 발생하면 어떻게 해결하는가
        </h1>
        <p className="detail-text">
          저는 에러가 발생하면 우선 <b>콘솔을 먼저 확인</b>해봅니다.
          <br />
          대부분의 에러들은{" "}
          <u>콘솔창에 에러의 원인과 해결 방안을 제시해주기 때문</u>입니다.
          <br />
          제시된 원인과 해결 방안을 시도해보고 해결되지않으면{" "}
          <b>블로그와 stackoverflow에 검색</b>해봅니다.
          <br />
          그럼에도 해결되지않는다면, <b>
            시도한 방법들을 블로그에 기록
          </b>하고 <u>시행 결과를 비교•분석하여 원인을 찾아 해결</u>
          합니다.
          <br />
          <br />
          <b>LMS 사이트를 개발</b>하면서 백으로 보내는{" "}
          <b>GET요청이 모두 실패하는 문제</b>를 발견하였습니다.
          <br />
          log를 확인해보니 <u>첫 번째 비동기요청에서 fetch error가 발생</u>
          하였고,
          <br />
          <u>Promise.all의 특성상 하나의 요청 실패가 모든 요청의 실패</u>로
          이어짐을 알게 되었습니다.
          <br /> 발생한 에러코드에 맞춰 백엔드 예외처리를 하였으나 여전히 문제가
          해결되지 않아 다른 관점으로 생각해보았습니다.
          <br />{" "}
          <b>
            ' 비동기요청 일부실패시에도 다른 요청들이 작동할 수 있는 메서드가
            있지않을까?'{" "}
          </b>
          라는 생각으로 검색을 하였고,
          <br /> <b>Promise.allSettled()를 발견</b>하였습니다.
          <br />이 메서드는{" "}
          <u>모든 요청이 병렬적으로 실행되지만, 독립적으로 처리</u>되기때문에
          나머지 요청에 영향을 주지않았습니다.
          <br />
          덕분에 <b>문제를 해결함과 동시에 서비스의 안정성을 확보</b>할 수
          있었습니다.
          <br />이 과정에서 <b>문제 해결 능력</b>과 <b>기술적 통찰력</b>을 키울
          수 있었고, 코딩에는 정답이 없다는 점을 다시 한번 깨달았습니다.
        </p>
      </section>
      <section id="my-road" data-aos="flip-left" data-aos-delay="400">
        <h1 className="jua-regular section-title">
          어떤 생각으로 무엇을 공부를 하였나
        </h1>
        <div className="text-box">
          <h1>Node.js 백엔드 개발</h1>
          <h4 className="norm-weight">
            백과 프론트가 함께 고민하며 개발할 수 있다는 매력에 Node.js 개발자가
            되었습니다.
          </h4>
          - MS 아키텍처와 <b>MSA 아키텍처로 서비스를 구현</b>하여 장점과 단점을
          경험하고 이해
          <br /> - <b>REST API 설계 및 서버 개발</b> <br />- <b>JWT</b>를 사용한
          stateless 인증 방식 설계, 토큰 탈취 방지 메커니즘 구현 경험
          <br />- <b>RDBMS</b>를 이해하고 서비스
          <b>ERD와 데이터베이스 설계 및 운용</b>
          <br />- <b>CLI</b> 환경의 Git과 MySQL에 능숙
          <br />
          <br />
          <br />
          <div className="why-box" data-aos="flip-up" data-aos-delay="400">
            <img className="think-image" src="/images/think.png" />
            <div>
              <h4>
                Q1 . 사용자란 누구이며 내가 사용자를 위해 무엇을 할 수 있을까?
              </h4>
              ⇨ 내가 만든서비스를 이용하는 고객과 나의 코드를 읽고 사용하는 동료
              개발자
              <br /> <br />
              <b>
                Q2 . 이 사용자들을위해 백엔드 개발자로서 내가 어떻게해야 더 좋은
                서비스를 제공할까?
              </b>
              <br />
              <br />⇨ 프론트엔드 개발자가되어 직접 경험하고 생각해보자!
            </div>
          </div>
          <br /> <br />
          <div>
            <h1>React 프론트엔드 개발 </h1>
            <p>
              소프트 스킬 향상과 프론트 개발자로의 사고방식과 문제 해결을
              경험해보고자 1인 프론트 개발자로 프로젝트를 진행하였습니다.
            </p>
            - PDM과 팀장 역할을 맡아 팀의 협업과 분위기를 긍정적으로 이끎
            <br />-{" "}
            <b>
              유저플로우를 분석하고 직관적인 UI를 설계 및 반응형 페이지를
              구현하여 사용자 경험을 증진
            </b>
            <br /> - 원활한 커뮤니케이션을 위해 <b>Agile Scrum</b> 방법을
            도입(스프린트 플래닝, 매일 스탠드업 미팅)
            <br />- 개발 초기 단계에서
            <b> Mock Data를 사용하여 백엔드와 원활한 통신</b>
            보장
            <br />- 서버 요청시<b> 비동기</b> 처리하여
            <b>성능 최적화및 블로킹 이슈 방지</b>
            <br />- <b>다양한 인증/인가 이해</b> (OAtuh2.0, JWT기반의
            AceesToken/RefreshToken)
            <br /> - <b>컴포넌트 재사용을 통한 클린코드 지향</b>
            <br /> - 다양한 라이브러리의 <b>docs를 읽고 적용 가능</b>
            <br /> - cypress를 사용한 <b>E2E 테스트 경험</b>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="why-box" data-aos="flip-up">
          <div>
            <h4>
              Q1 . CI/CD와 배포 자동화에대해 돈 걱정없이 제대로 공부하고싶다.
            </h4>
            <h4>
              Q2 . 아직까지 JAVA기반의 회사가많은데 Java는 Nodejs랑 뭐가 다를까?
            </h4>
            ⇨ NaverCloude에서 지원하는 Java기반 DevOps 과정을 진행해보자!
          </div>
          <img className="think-image2" src="/images/think2.png" />
        </div>
        <br />
        <br />
        <br />
        <div className="text-box">
          <h1>Java 풀스택 개발 & DevOps </h1>- JFrame ~ SpringBoot를 사용하여
          프로젝트를 진행
          <br />- JQuery, JSP, React를 사용하여 다양한 웹 기술 적용
          <br /> - <b>ORM(JPA)을 사용한 비지니스 설계 및 구현</b>
          <br />- VPC, ACG, ASG, LoadBlancing 등 다양한
          <b> 클라우드 서비스를 이해하고 경험</b>
          <br />- <b>Jenkins, Docker를 사용한 CI/CD 파이프라인 구축</b>
          <br />- Git Hooks와 NaverCloudPlatform을 사용한 <b>배포 자동화</b>
        </div>
      </section>
    </div>
  );
};

export default AboutMe;
