import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Instance from "../../utils/axios";

const Sidebar = ({ showSide, setShowSide }) => {
  const [projects, setProjects] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    Instance.get("/data/projects.json")
      .then((res) => {
        setProjects(res.data);
        const pathProject = location.pathname.split("/")[2];
        const projectIndex = res.data.findIndex(
          (project) => project.cardClassName === pathProject
        );
        if (projectIndex !== -1) {
          setSelectedTitle(projectIndex);
        }
      })
      .catch((err) => console.error(err));
  }, [location.pathname]);

  const handleClick = (idx) => {
    setSelectedTitle(idx);
    navigate(`/projects/${projects[idx].cardClassName}`);
  };

  const handleClose = () => {
    setShowSide(false);
  };

  const handleOpen = () => {
    setShowSide(true);
  };

  return (
    <aside className={`sidebar ${showSide ? "visible" : "hidden"}`}>
      <ul className="projects-list">
        {projects.map((el, idx) => {
          const titleParts = el.title.split("(");

          return (
            <li
              key={idx}
              className={`project-name ${
                selectedTitle === idx ? "active" : ""
              }`}
              onClick={() => handleClick(idx)}
            >
              {titleParts.length > 1 ? (
                <>
                  {titleParts[0]}
                  <br />({titleParts[1]}
                </>
              ) : (
                el.title
              )}
            </li>
          );
        })}
      </ul>
      {showSide ? (
        <button className="show-button close-button" onClick={handleClose}>
          CLOSE
        </button>
      ) : (
        <button className="show-button open-button" onClick={handleOpen}>
          OPEN
        </button>
      )}
    </aside>
  );
};

export default Sidebar;
