import React, { useEffect, useRef } from "react";
import "./NotFound.scss";

const NotFound = () => {
  const magnifierRef = useRef(null);
  const pageRef = useRef(null);

  useEffect(() => {
    const magnifier = magnifierRef.current;
    const page = pageRef.current;

    const handleMouseMove = (e) => {
      const mouseX = e.pageX - page.offsetLeft;
      const mouseY = e.pageY - page.offsetTop;

      if (
        mouseX < page.offsetWidth &&
        mouseY < page.offsetHeight &&
        mouseX > 0 &&
        mouseY > 0
      ) {
        magnifier.style.display = "block";
      } else {
        magnifier.style.display = "none";
      }

      if (magnifier.style.display === "block") {
        const backgroundX =
          -(mouseX / page.offsetWidth) * page.scrollWidth -
          magnifier.offsetWidth / 2;
        const backgroundY =
          -(mouseY / page.offsetHeight) * page.scrollHeight -
          magnifier.offsetHeight / 2;
        const px = mouseX - magnifier.offsetWidth / 2;
        const py = mouseY - magnifier.offsetHeight / 2;

        magnifier.style.left = `${px}px`;
        magnifier.style.top = `${py}px`;
        magnifier.style.backgroundPosition = `${backgroundX}px ${backgroundY}px`;
      }
    };

    page.addEventListener("mousemove", handleMouseMove);

    return () => {
      page.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="page-wrapper" ref={pageRef}>
      <div className="magnifier" ref={magnifierRef}></div>
      <div className="body-content">
        <h1>404 Not Found</h1>
        <h2>클릭!</h2>
      </div>
    </div>
  );
};

export default NotFound;
