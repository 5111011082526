import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./ProjectDetail.scss";
import LearnHub from "../../components/Projects/LearnHub/LearnHub";
import WhoDucKing from "../../components/Projects/WhoDucKing/WhoDucKing";
import MakingPage from "../../components/Making/Making";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const [showSide, setShowSide] = useState(true);

  const renderProjectComponent = () => {
    switch (projectId) {
      case "LearnHub":
        return <LearnHub />;
      case "WhoDucKing":
        return <WhoDucKing />;
      default:
        return <MakingPage />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="detail-body">
      <Sidebar
        showSide={showSide}
        setShowSide={setShowSide}
        className="sidebar"
      />
      <div className={`project-detail-container ${showSide ? "" : "centered"}`}>
        {renderProjectComponent()}
      </div>
    </div>
  );
};

export default ProjectDetail;
